<style lang="sass" scoped>
// .router-link-active
//   font-weight: bold
//   color: #007bff
//   background-color: darken(#f8f9fa, 3%)
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div
  .bg-white.shadow-sm.mb-2
    .p-3
      .mb-4
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/document`')
          strong Workspace 정보
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/email`')
          strong 이메일 발신자
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/sms`')
          strong 문자 발신자
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/kakao`')
          strong 카카오 알림톡
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/db`')
          strong DB 연동정보
        //- router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/menu`')
          strong 메뉴관리

      router-view

      .mt-4(v-if='ENV != `production`')
        strong.flag QA

        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/status-flow`')
          strong 고객/주문 상태관리
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/canceled-orders`')
          strong 고객/주문 취소내역
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/message-template`')
          strong 메시지 템플릿 관리
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/import`')
          strong 가져오기 import
        router-link.sub-link.mr-3(:to='`/property/${property.id}/manage/export`')
          strong 내보내기 export
        router-link.link.btn.px-0.py-3.text-dark.mr-3(:to='`/property/${property.id}/order`') 주문
          br
          small 구버전
        router-link.link.btn.px-0.py-3.text-dark.mr-3(:to='`/property/${property.id}/views`') 고객뷰
          br
          small 구버전




</template>

<script>

const ENV = process.env.NODE_ENV

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      loading: false,
      property: [],
      ENV,
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}`)
        this.property = r.data.property,
        this.loading = true
      } catch (error) {
        console.log({error})
      }
    },

    open_noti() {
      alert(`알림 표시합니다: 문자답변, 이메일답변, 가져오기성공`)
    },

  },
}
</script>
